export default {
    data() {
        return {
            toasterSettings: {
                position: "top-right",
                timeout: 3500,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: true,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false
            }
        }
    },
    methods: {
        success(message) {
            this.$toast.success(message, this.toasterSettings);
        },
        warning(message) {
            this.$toast.warning(message, this.toasterSettings);
        },
        err(message) {
            this.$toast.error(message, this.toasterSettings);
        },
        info(message) {
            this.$toast.info(message, this.toasterSettings);
        }
    },
}