import { mapGetters, mapActions } from "vuex"

export default {
    data() {
        return {
            inputFieldsWithIcons: ["Password", "Show-Password", "Currency", "Percent"],
            validEmail: true,
            validPhoneNumber: true,
            validSecurityNumber: true,
            uniqueSSN: true,
            validStateCode: true,
            validZipCode: true,
            skipTheValidation: false
        }
    },
    computed: {
        ...mapGetters(['getAppFlow', 'getProspectId', 'getOrgId', 'getCompanyName', 'getUserId', 'getActiveSection', 'getActiveQuestion', 'getQuestions', 'getSubQuestions', 'getIsAuthenticated', 'getAllAddresses', 'getAllEmployment', 'getAllMcQuestions', 'getSubjectPropertyStatus', "getEditModeFlag"]),

        queIdExistsInQueText() {
            let string = this.question.questionText;
            let question_id;

            if (string) {
                question_id = string.substring(
                    string.indexOf("{") + 1,
                    string.lastIndexOf("}")
                );
            }

            return question_id || false;
        },

        getQueWithName() {
            if (this.queIdExistsInQueText) {
                let string = this.question.questionText;
                let question_id = string.substring(
                    string.indexOf("{"),
                    string.lastIndexOf("}") + 1
                );

                let get_name = this.getQuestions.find(
                    (v) => v.questionId === this.queIdExistsInQueText
                );

                let que_text = get_name
                    ? string.replace(question_id, get_name.questionAnswer)
                    : string;

                return que_text || "";
            }

            return "";
        },

        queIdExistsInSubGroupName() {
            let string = this.question.subGroupName;
            let question_id;

            if (string) {
                question_id = string.substring(
                    string.indexOf("{") + 1,
                    string.lastIndexOf("}")
                );
            }

            return question_id || false;
        },

        getSubGroupNameWithName() {
            if (this.queIdExistsInSubGroupName) {
                let string = this.question.subGroupName;

                let question_id = string.substring(
                    string.indexOf("{"),
                    string.lastIndexOf("}") + 1
                );

                let get_name = this.getQuestions.find(
                    (v) => v.questionId === this.queIdExistsInSubGroupName
                );

                let sub_group_name = get_name
                    ? string.replace(question_id, get_name.questionAnswer)
                    : string;

                return sub_group_name || "";
            }

            return "";
        },

        getApplicantName() {
            let get_name = this.getQuestions.find(
                (v) => v.questionFieldMapping === 'firstname__c' || v.questionFieldMapping === 'candidoem__firstname__c'
            );

            return get_name?.questionAnswer ? get_name.questionAnswer : "Applicant";
        },

        getCoApplicantName() {
            let get_name = this.getQuestions.find(
                (v) => v.questionFieldMapping === 'first_name_co_borrower__c' || v.questionFieldMapping === 'candidoem__first_name_co_borrower__c'
            );

            return get_name?.questionAnswer ? get_name.questionAnswer : "Co-applicant";
        }
    },
    methods: {
        ...mapActions([
            'fetchAssets',
            'fetchActiveQuestion',
            'fetchQuestions',
            'fetchSubQuestions',
            'fetchAllAddresses',
            'fetchAllEmployment',
            'fetchAllMcQuestions',
            'fetchLoaderStatus',
            'fetchCompLoaderStatus',
            'fetchShowInternalAuth',
            'fetchSubjectPropertyStatus',
            'fetchEditModeFlag',
            'addAnswerLocally',
            'addMultipleAnswersLocally',
            'addMainAndSubAnswersLocally',
            'updateQueObjectWithAnswer',
            'resetGlobalStore',
            'resetUserStore',
            'resetQuestionnaireStore',
            'resetAddressStore',
            'resetEmploymentStore',
            'resetMcStore',
        ]),

        async getAppAssets() {
            this.fetchLoaderStatus(true);

            await this.$http
                .get(
                    "/account/GetDMAAsset?prospectId=" + this.getProspectId + "&orgId=" + this.getOrgId
                )
                .then(async (response) => {
                    let resp = response.data;

                    if (resp?.status === 511) {
                        this.resetGlobalStore();
                        this.resetUserStore();
                        this.resetQuestionnaireStore();
                        this.resetAddressStore();
                        this.resetEmploymentStore();
                        this.resetMcStore();
                        this.$router.push("/");
                        this.err(resp.message);
                    }

                    if (resp.response === "Success") {
                        if (resp.assetData) {
                            this.fetchAssets(resp.assetData);

                            const favicon = document.getElementById("favicon");
                            favicon.setAttribute("href", resp.assetData.roundColorTeamLogo);

                            document.title = resp?.assetData?.loCompany
                                ? resp.assetData.loCompany + " Digital Mortgage Application"
                                : "Digital Mortgage Application";
                        }
                    }

                    if (resp.response === "Error") console.log(resp.message);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        unAuthorizedAccess(que) {
            if (que.needsAuthentication && !this.getIsAuthenticated)
                this.fetchShowInternalAuth(true)
        },

        checkTheDependancy(que) {
            if (que.dependentQuestionId) {
                let queExists = this.getQuestions.find(x => x.questionId === que.dependentQuestionId);

                if (queExists) {
                    if (queExists.questionType === 'MultipleChoice') {

                        let theDependantArr = que.dependentQuestionAnswer.split("||");

                        if (theDependantArr.length) {
                            if (!queExists.questionAnswer?.length) {
                                if (this.currentaq > this.previousaq) {
                                    this.skipTheValidation = true;
                                    this.nextQuestion();
                                }

                                if (this.currentaq < this.previousaq) this.previousQuestion();
                            } else {
                                if (!queExists.questionAnswer.some(r => theDependantArr.includes(r))) {
                                    let updateAns = this.getQuestions.filter((z) => z.dependentQuestionId === queExists.questionId);

                                    updateAns.forEach((v) => {
                                        v.questionAnswer = "";
                                    });

                                    if (this.currentaq > this.previousaq) {
                                        this.skipTheValidation = true;
                                        this.nextQuestion();
                                    }

                                    if (this.currentaq < this.previousaq) this.previousQuestion();
                                }
                            }
                        }
                    } else {
                        if (queExists.questionAnswer !== que.dependentQuestionAnswer) {
                            let updateAns = this.getQuestions.filter((z) => z.dependentQuestionId === queExists.questionId);

                            updateAns.forEach((v) => {
                                v.questionAnswer = "";
                            });

                            if (this.currentaq > this.previousaq) this.nextQuestion();
                            if (this.currentaq < this.previousaq) this.previousQuestion();
                        }
                    }
                }
            }
        },

        previousQuestion() {
            if (this.getActiveQuestion === 1)
                this.$router.push({ path: '/' + this.getCompanyName + '/' + this.getProspectId + '&' + this.getOrgId });
            if (this.getActiveQuestion > 1) this.getExactPreviousIndex();
        },

        getExactPreviousIndex() {
            let theFields = [];
            let previousQuestion = this.getQuestions.find(
                (i) => i.questionIndex === this.getActiveQuestion - 1
            );

            if (previousQuestion.groupName) {
                for (let i = previousQuestion.questionIndex - 1; i > 0; i--) {
                    if (
                        this.getQuestions[i].groupName === null ||
                        this.getQuestions[i].groupName !== previousQuestion.groupName
                    ) {
                        break;
                    }
                    theFields.push(this.getQuestions[i]);
                }

                this.fetchActiveQuestion(theFields.at(-1).questionIndex);
            }

            if (!previousQuestion.groupName && this.getActiveQuestion > 1)
                this.basicDecrement();
        },

        getFieldType(que) {
            let type = que.questionType.toLowerCase();

            let numberTypes = ['percent', 'currency'];
            let phoneNumber = ['phone'];

            if (numberTypes.includes(type)) return "number";
            if (phoneNumber.includes(type)) return 'text';
            else return type;
        },

        getInputMode(que) {
            let type = que.questionType.toLowerCase();

            let currencyTypes = ['percent', 'currency'];

            if (currencyTypes.includes(type)) return 'decimal';
            if (type === 'phone') return 'tel';
            if (type === 'number') return 'numeric';
            if (type === 'email') return 'email';

            return "";
        },

        getIcons(type) {
            if (type === "Password") return "fa fa-lock";
            if (type === 'Show-Password') return "fa fa-lock";
            if (type === "Currency") return "fas fa-dollar-sign";
            if (type === "Percent") return "fas fa-percentage";
        },

        checkEmail(email) {
            let emailRegex = /^\S+@\S+\.\S+$/;
            this.validEmail = emailRegex.test(email);
        },

        checkPhoneNumber(number) {
            if (number) {
                number = number.replaceAll("-", "");
                this.validPhoneNumber = number.length === 10
            }
        },

        checkSecurityNumber(number) {
            number = number.replaceAll("-", "");
            this.validSecurityNumber = number.length === 9
        },

        checkStateCode(code) {
            this.validStateCode = code.length === 2 ? true : false;
        },

        checkZipCode(code, isManual) {
            if (code.length === 5) {
                if (isManual && this.theAnswers[0]) {
                    this.$http
                        .get(`https://maps.googleapis.com/maps/api/geocode/json?address=${this.theAnswers[0]}, ${this.theAnswers[2]}&sensor=true&location_type=ROOFTOP&result_type=street_address&key=AIzaSyDYYKos_zgje0clujI_sKRXYsPrDo1k2FY`)
                        .then(response => {
                            let resp = response.data

                            if (resp.status === 'OK') {
                                let fetchZip = resp.results[0].address_components.find(rec => rec.types[0] === 'postal_code')

                                if (fetchZip) {
                                    this.validZipCode = fetchZip.long_name === code
                                    return
                                } else {
                                    let latitude = resp.results[0].geometry.location.lat
                                    let longitude = resp.results[0].geometry.location.lng

                                    this.$http
                                        .get(
                                            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true&result_type=postal_code&key=AIzaSyDYYKos_zgje0clujI_sKRXYsPrDo1k2FY`
                                        )
                                        .then(response => {
                                            let resp = response.data

                                            if (resp.status === 'OK') {
                                                let getZip = resp.results[0].address_components.find(
                                                    (el) => el.types[0] === "postal_code"
                                                );

                                                this.validZipCode = getZip.long_name === code
                                                return
                                            }
                                        })
                                        .catch(error => console.log(error))
                                }
                            }

                            if (resp.status === 'ZERO_RESULTS') {
                                this.err("Please enter complete street number and name")
                            }
                        })
                        .catch(error => console.log(error))
                } else {
                    this.validZipCode = true
                    return
                }
            }

            this.validZipCode = false
        },

        checkDuplicateSSN(ssn) {
            let theSSN;

            if (this.getActiveSection === 'Personal Information') {
                theSSN = this.getQuestions.find(x => x.questionFieldMapping === 'co_social_security_number__c' || x.questionFieldMapping === 'candidoem__co_social_security_number__c')
            } else {
                theSSN = this.getQuestions.find(x => x.questionFieldMapping === 'social_security_number__c' || x.questionFieldMapping === 'candidoem__social_security_number__c')
            }

            if (theSSN?.questionAnswer !== ssn) this.uniqueSSN = true
            else this.uniqueSSN = false
        },

        capitalizeFirstChar(word) {
            return word ? word.charAt(0).toUpperCase() + word.slice(1) : ''
        }
    },
}