const defaultState = () => {
    return {
        allAddresses: [],
        refinanceSubjectProperty: false,
    }
}

const state = defaultState()

const getters = {
    getAllAddresses: state => state.allAddresses,
    getSubjectPropertyStatus: state => state.refinanceSubjectProperty
}

const actions = {
    fetchAllAddresses({ commit }, data) {
        commit('setAllAddresses', data);
    },

    fetchSubjectPropertyStatus({ commit }, data) {
        commit('setSubjectPropertyStatus', data);
    },

    resetAddressStore({ commit }) {
        commit('resetAddressState')
    }
}

const mutations = {
    setAllAddresses: (state, addresses) => state.allAddresses = addresses,
    setSubjectPropertyStatus: (state, status) => state.refinanceSubjectProperty = status,

    resetAddressState(state) {
        Object.assign(state, defaultState())
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}