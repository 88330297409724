const defaultState = () => {
    return {
        allEmployment: []
    }
}

const state = defaultState()

const getters = {
    getAllEmployment: state => state.allEmployment
}

const actions = {
    fetchAllEmployment({ commit }, data) {
        commit('setAllEmployment', data);
    },

    resetEmploymentStore({ commit }) {
        commit('resetEmploymentState')
    }
}

const mutations = {
    setAllEmployment: (state, employment) => state.allEmployment = employment,

    resetEmploymentState(state) {
        Object.assign(state, defaultState())
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}