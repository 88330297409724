<template>
  <main :style="cssProps">
    <loader v-if="getLoaderStatus" />
    <router-view></router-view>
  </main>
</template>

<script>
import Loader from "./components/Loader.vue";
import { mapGetters, mapActions } from "vuex";
import "./filters.js";
import "./custom.js";
import commonfunctions from "./mixins/commonfunctions";

export default {
  name: "App",
  mixins: [commonfunctions],
  components: {
    Loader,
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      async handler(newVal) {
        if (newVal?.name) {
          if (newVal.name === "Index") await this.fetchDataFromUrl();
          if (newVal.name !== "Index" && newVal.name !== "Under-Construction")
            await this.getAppAssets();
        }
      },
    },
  },
  computed: {
    ...mapGetters([
      "getOrgId",
      "getProspectId",
      "getAssets",
      "getLoaderStatus",
    ]),

    cssProps() {
      return {
        "--background-image": this.getAssets.agentPortalBackground,
        "--primary-color": this.getAssets.primaryBrandColor || "#A6383C",
      };
    },
  },
  methods: {
    ...mapActions(["fetchProspectId", "fetchOrgId", "fetchCompanyName"]),

    async fetchDataFromUrl() {
      let theUrl = this.$route.params.unique_key.split("&");

      this.fetchProspectId(theUrl[0] || this.getProspectId);
      this.fetchOrgId(theUrl[1] || this.getOrgId);
      this.fetchCompanyName(this.$route.params.pathMatch);
    },
  },
};
</script>

<style>
@import "./assets/css/app.css";
@import "./assets/css/custom.css";

body::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}
main::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}
main .wrapper .main-content::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}
.form-content::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}
.form-content .form-element .answer ul li input[type="radio"]:checked + label {
  border-color: var(--primary-color);
}
.form-content .form-element .answer ul li label::before {
  background-color: var(--primary-color);
}
.form-control:focus {
  border: 1px solid var(--primary-color);
}
.right {
  background-color: var(--primary-color);
}
.btn.btn-red:before {
  background: var(--primary-color);
}
.btn.btn-red::after {
  border: 1px solid var(--primary-color);
}
.btn.btn-red:hover span {
  color: var(--primary-color);
}
.btn.btn-white span {
  color: var(--primary-color);
}
.accordion ul li .checkbox input:checked + label::before {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
}
.input-group-text {
  color: var(--primary-color);
}
.input-group-eye {
  color: #55555585;
}
.input-group input.form-control:focus {
  border-color: var(--primary-color);
}
.small-radio input[type="radio"]:checked + label {
  border-color: var(--primary-color);
}
.checkbox label:before {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
aside .sidebar ul li.completed .icon::after {
  border: solid var(--primary-color);
  border-width: 0 2px 2px 0;
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  box-shadow: none;
}
.accordion ul li .checkbox input:checked + label::before {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
}
.sign-in-up.forgot-password .form .form__content form.border-none p .text-red {
  color: var(--primary-color);
}
.dropdown .dropdown-menu li .dropdown-item:active {
  background-color: var(--primary-color);
}
.radio-button input:checked + label::before {
  border: 6px solid var(--primary-color);
}
input[type="password"] {
  font-family: caption;
  font-size: 20px;
  font-weight: bolder;
}
input[type="password"]::-webkit-input-placeholder {
  font-size: 15px;
  font-weight: normal;
  font-family: "Lato", sans-serif;
}
select.form-select:focus {
  border: 1px solid var(--primary-color);
}
main .wrapper .main-content .text-red {
  color: var(--primary-color);
}
.close-fa-fa-icon {
  color: var(--primary-color);
}
@media only screen and (max-width: 580px) {
  .right .nav-link a span {
    color: var(--primary-color);
  }

  .right .nav-link a:hover span {
    color: var(--primary-color);
  }
}
.bg-dynamic {
  background: var(--primary-color) !important;
  opacity: 1;
}
.modal.termsOfUseModal .modal-dialog .modal-content,
.modal.privacyPolicyModal .modal-dialog .modal-content,
.modal.consentToContactModal .modal-dialog .modal-content,
.modal.consentToUseRecordsModal .modal-dialog .modal-content {
  text-align: justify;
  border-top: 5px solid var(--primary-color);
}
label .dynamic-color-icons {
  max-width: 50px;
  background: var(--primary-color);
}

.border-checkbox label::before {
  background-color: transparent !important;
  border-color: var(--primary-color);
}

.border-checkbox > input:checked + label::before {
  background-color: var(--primary-color) !important;
  border-color: #ffffff;
}

.annual-income.form-content
  .form-element
  .accordion-container
  ul.accordion
  li.accordion__item {
  border-bottom: 1px solid var(--primary-color);
}

.annual-income.form-content
  .form-element
  .accordion-container
  ul.accordion
  li.accordion__item:first-child {
  border-top: 1px solid var(--primary-color);
}

.annual-income.form-content
  .form-element
  ul.accordion
  li.accordion__item
  .checkbox
  > label:before {
  border: 1px solid var(--primary-color);
}

.annual-income.form-content
  .form-element
  ul.accordion
  li.accordion__item
  .checkbox
  > input:checked
  + label:before {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

@media (min-width: 768px) and (max-width: 991px) {
  .annual-income.form-content
    .form-element
    ul.accordion
    li.accordion__item
    .contents
    .label-input
    .input-group
    .icon {
    color: #d1362f;
  }
  .annual-income.form-content
    .form-element
    ul.accordion
    li.accordion__item
    .checkbox
    .radio-button
    > input:checked
    + label::before {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
}
</style>