const router = [
    {
        path: "/*/:unique_key",
        component: () => import('./components/Index'),
        name: "Index",
        default: true
    },
    {
        path: '/',
        component: () => import('./components/UnderConstruction'),
        name: 'Under-Construction',
        default: true
    },
    {
        path: "/questionnaire",
        component: () => import('./components/Layout'),
        name: "Layout",
        default: true
    },
    {
        path: "/sign-up",
        component: () => import('./components/SignUp'),
        name: "SignUp",
        default: true
    },
    {
        path: "/sign-in",
        component: () => import('./components/SignIn'),
        name: "SignIn",
        default: true
    },
    {
        path: "/log-out",
        component: () => import('./components/LogOut'),
        name: "LogOut",
        default: true
    },
    {
        path: "/forgot-password",
        component: () => import('./components/ForgotPassword'),
        name: "ForgotPassword",
        default: true,
    },
    {
        path: "/reset-password",
        component: () => import('./components/ResetPassword'),
        name: "ResetPassword",
        default: true,
    },
    {
        path: "/change-email",
        component: () => import('./components/ChangeEmail'),
        name: "ChangeEmail",
        default: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/change-password",
        component: () => import('./components/ChangePassword'),
        name: "ChangePassword",
        default: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/submit-application",
        component: () => import('./components/SubmitApplication'),
        name: "SubmitApplication",
        default: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/switch-application",
        component: () => import('./components/SwitchApplication'),
        name: "SwitchApplication",
        default: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/congratulations",
        component: () => import('./components/Congratulations'),
        name: "Congratulations",
        default: true,
        meta: {
            requiresAuth: true,
        },
    },
]

export default router