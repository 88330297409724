const defaultState = () => {
    return {
        prospectEmail: "",
        userId: "",
        isAuthenticated: false,
        showInternalAuth: false,
        userDetails: {},
        applications: [],
        createAppFlag: false,
        cloneAppId: "",
        cloneAppFlag: false,
        applyAgainFlag: false,
        editModeFlag: false,
        isMismoFileCreated: false,
        socialLogin: '',
        facebookData: {},
        facebookScope: {},
        facebookModel: {}
    }
};

const state = defaultState();

const getters = {
    getProspectEmail: state => state.prospectEmail,
    getUserId: state => state.userId,
    getIsAuthenticated: state => state.isAuthenticated,
    getShowInternalAuth: state => state.showInternalAuth,
    getUser: state => state.userDetails,
    getApplications: state => state.applications,
    getCreateAppFlag: state => state.createAppFlag,
    getCloneAppId: state => state.cloneAppId,
    getCloneAppFlag: state => state.cloneAppFlag,
    getApplyAgainFlag: state => state.applyAgainFlag,
    getEditModeFlag: state => state.editModeFlag,
    getIsMismoFileCreated: state => state.isMismoFileCreated,
    getSocialLogin: state => state.socialLogin,
    getFacebookData: state => state.facebookData,
    getFacebookScope: state => state.facebookScope,
    getFacebookModel: state => state.facebookModel
};

const actions = {
    fetchProspectEmail({ commit }, data) {
        commit('setProspectEmail', data);
    },

    fetchIsAuthenticated({ commit }, data) {
        commit('setIsAuthenticated', data);
    },

    fetchShowInternalAuth({ commit }, data) {
        commit('setShowInternalAuth', data);
    },

    fetchUser({ commit }, data) {
        commit('setUser', data);
    },

    fetchApplications({ commit }, data) {
        commit('setApplications', data);
    },

    fetchCreateAppFlag({ commit }, data) {
        commit('setCreateAppFlag', data);
    },

    fetchCloneAppId({ commit }, data) {
        commit('setCloneAppId', data);
    },

    fetchCloneAppFlag({ commit }, data) {
        commit('setCloneAppFlag', data);
    },

    fetchApplyAgainFlag({ commit }, data) {
        commit('setApplyAgainFlag', data);
    },

    fetchEditModeFlag({ commit }, data) {
        commit('setEditModeFlag', data);
    },

    fetchUserId({ commit }, data) {
        commit("setUserId", data);
    },

    fetchIsMismoFileCreated({ commit }, data) {
        commit('setIsMismoFileCreated', data);
    },

    fetchSocialLogin({ commit }, data) {
        commit('setSocialLogin', data);
    },

    fetchFacebookData({ commit }, data) {
        commit('setFacebookData', data);
    },

    fetchFacebookScope({ commit }, data) {
        commit('setFacebookScope', data);
    },

    fetchFacebookModel({ commit }, data) {
        commit('setFacebookModel', data);
    },

    updateApplications({ commit, getters }, data) {
        let apps = getters.getApplications;

        if (apps?.length && data?.length) {
            data.forEach(x => {
                apps.push(x);
            });
        }

        commit('setApplications', apps);
    },

    resetUserStore({ commit }) {
        commit('resetUserState')
    }
};

const mutations = {
    setProspectEmail: (state, email) => state.prospectEmail = email,
    setIsAuthenticated: (state, isauthenticated) => state.isAuthenticated = isauthenticated,
    setShowInternalAuth: (state, internalauth) => state.showInternalAuth = internalauth,
    setUser: (state, user) => state.userDetails = user,
    setApplications: (state, apps) => state.applications = apps,
    setCreateAppFlag: (state, create) => state.createAppFlag = create,
    setCloneAppId: (state, clone_app_id) => state.cloneAppId = clone_app_id,
    setCloneAppFlag: (state, clone) => state.cloneAppFlag = clone,
    setApplyAgainFlag: (state, apply_again) => state.applyAgainFlag = apply_again,
    setEditModeFlag: (state, edit_flag) => state.editModeFlag = edit_flag,
    setUserId: (state, userid) => state.userId = userid,
    setIsMismoFileCreated: (state, ismismofilecreated) => state.isMismoFileCreated = ismismofilecreated,
    setSocialLogin: (state, sociallogin) => state.socialLogin = sociallogin,
    setFacebookData: (state, fbdata) => state.facebookData = fbdata,
    setFacebookScope: (state, fbscope) => state.facebookScope = fbscope,
    setFacebookModel: (state, fbmodel) => state.facebookModel = fbmodel,

    resetUserState(state) {
        Object.assign(state, defaultState())
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
