const defaultState = () => {
    return {
        allMcQuestions: {}
    }
}

const state = defaultState()

const getters = {
    getAllMcQuestions: state => state.allMcQuestions
}

const actions = {
    fetchAllMcQuestions({ commit }, data) {
        commit('setAllMcQuestions', data);
    },

    resetMcStore({ commit }) {
        commit('resetMcState')
    }
}

const mutations = {
    setAllMcQuestions: (state, McQuestions) => state.allMcQuestions = McQuestions,

    resetMcState(state) {
        Object.assign(state, defaultState())
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}