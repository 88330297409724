import Vue from 'vue'
import Vuex from 'vuex'
import Global from './modules/global'
import User from './modules/user'
import Questionnaire from './modules/questionnaire'
import Addresses from './modules/address'
import Employment from './modules/employment'
import MultipleChoice from "./modules/multiplechoice"
import SecureLS from 'secure-ls'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)
const localStore = new SecureLS({
    encodingType: 'aes',
    isCompression: true,
});

export default new Vuex.Store({
    modules: { Global, User, Questionnaire, Addresses, Employment, MultipleChoice },
    plugins: [createPersistedState({
        paths: ['Global', 'User', 'Questionnaire', 'Addresses', 'Employment', 'MultipleChoice'],
        storage: {
            getItem: key => localStore.get(key),
            setItem: (key, value) => localStore.set(key, value),
            removeItem: key => localStore.remove(key),
        },
    })],
})